import React from "react"
// import { Link } from "gatsby"
// import styles from "../styles/components/footer.module.scss"

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div>This is a temp footer</div>
      </footer>
    )
  }
}

export default Footer
